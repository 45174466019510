<template>
  <div class="box-page" style="padding-right: 15px">
    <a-form layout="horizontal" :mode="formSearch">
      <a-row gutter="20">
        <a-col span="5">
          <a-form-item label="风险等级">
            <a-select v-model:value="formSearch.level" placeholder="请选择风险等级" allow-clear>
              <a-select-option :value="level.value"
                               :label="level.label"
                               v-for="level in levels"
                               :key="level.label">{{ level.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="5">
          <a-form-item label="关键词组">
            <a-select v-model:value="formSearch.keywordTypeId"
                      placeholder="请选择关键词组"
                      :filterOption="(input, option)=> { return option.label.indexOf(input) >= 0;}"
                      show-search allow-clear>
              <a-select-option :value="keyNameItem.value"
                               :label="keyNameItem.label"
                               v-for="keyNameItem in voiceOption"
                               :key="keyNameItem.label">{{ keyNameItem.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="5">
          <a-form-item label="关键词" name="keyword">
            <a-input v-model:value="formSearch.keyword" placeholder="请输入关键词"/>
          </a-form-item>
        </a-col>
        <a-col span="8">
          <a-form-item>
            <a-button type="primary" @click="getRuleList">查询</a-button>
            <a-button style="margin-left: 10px" @click="resetSearch">重置</a-button>
            <a-button type="primary" style="margin-left: 10px" @click="editData(null)">添加质检规则</a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <el-table
        v-loading="loading"
        ref="multipleTable"
        :data="tableData"
        size="mini"
        :row-style="{height:'50px'}"
        :cell-style="{padding:'0px'}"
        tooltip-effect="dark"
        style="width: 100%; overflow: auto"
        :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }">
      <el-table-column label="风险等级" width="100">
        <template #default="{ item }">
          <span>{{ levelDesc(item) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="触发方式" width="100">
        <template #default="{ item }">
          <span>{{ ruleTypeDesc(item) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="关键词组" width="100">
        <template #default="{ item }">
          <el-tooltip placement="top">
            <template #content>
              <p class="ellipsis">
                {{ item.name }}
              </p>
            </template>
            <p class="ellipsis">
              {{ item.name }}
            </p>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="关键词" width="100">
        <template #default="{ item }">
          <el-tooltip placement="top">
            <template #content>
              <p class="ellipsis">
                {{ item.name }}
              </p>
            </template>
            <p class="ellipsis">
              {{ item.name }}
            </p>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="规则" width="100">
        <template #default="{ item }">
          <el-tooltip placement="top">
            <template #content>
              <p class="ellipsis">
                {{ item.name }}
              </p>
            </template>
            <p class="ellipsis">
              {{ item.name }}
            </p>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="100">
        <template #default="{ item }">
          <span>{{ statusDesc(item) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建更新时间" width="100">
        <template #default="{ item }">
          <span>{{ statusDesc(item) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template #default="{ item }">
          <a-button size="small" style="margin-right: 8px" type="primary" @click="editData(item)">编辑</a-button>
          <a-popconfirm
              title="确定删除此条数据?"
              ok-text="确定"
              cancel-text="取消"
              @confirm="deleteData(item)"
          >
            <a-button size="small" type="primary" danger>删除</a-button>
          </a-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        @size-change="pageSizeChange"
        @current-change="pageChange"
        :current-page="pageModule.currentPage"
        :page-size="pageModule.pageSize"
        background :page-sizes="[10, 20, 30, 40]" layout="total, sizes, prev, pager, next, jumper"
        :total="pageModule.total">
    </el-pagination>

    <a-modal width="690px" v-model:visible="modalVisible" title="配置质检规则" @cancel="modalCanceled">
      <div class="add-from-s">
        <a-form layout="horizontal" :model="formData" :rule="formRules" ref="formRef">
          <a-form-item has-feedback label="风险等级" name="level">
            <a-radio-group v-model:value="formData.level">
              <a-radio :value="level.value" v-for="level in levels" :key="level.label">{{ level.label }}</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item has-feedback label="触发方式" name="ruleType">
            <a-radio-group v-model:value="formData.ruleType">
              <a-radio :value="ruleType.value" v-for="ruleType in ruleTypes" :key="ruleType.label">
                {{ ruleType.label }}
              </a-radio>
            </a-radio-group>
          </a-form-item>

          <a-form-item v-if="formData.ruleType==0" label="关键词组" name="keywordTypeId">
            <a-select
                v-model:value="formData.keywordTypeId"
                style="width: 100%"
                show-search allow-clear
                placeholder="请输入关键词组名称">
              <a-select-option
                  :value="item.value"
                  :label="item.label"
                  v-for="item in voiceOption"
                  :key="item.value">{{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item v-if="formData.ruleType==0" label="配置规则">
            <span>至少触发关键词组中的<a-input :style="{width: '80px',margin: '0px 5px', padding: '1px 5px'}"
                                               type="number" min="1" v-model:value="formData.keywordHitNum"
                                               placeholder=""/>个关键词视为命中该关键词组</span>
          </a-form-item>

          <a-form-item v-if="formData.ruleType==1" label="配置规则">
            <span>主叫
              <a-input :style="{width: '80px',margin: '0px 5px', padding: '1px 5px'}"
                       type="number" min="1" v-model:value="formData.upgradeDay"
                       placeholder=""/>天
              <a-input :style="{width: '80px',margin: '0px 5px', padding: '1px 5px'}"
                       type="number" min="1" v-model:value="formData.upgradeNum"
                       placeholder=""/>次 触发{{ lowerLevelLabel }}升级为{{ currentLevelLabel }}</span>
          </a-form-item>
          <a-form-item v-if="formData.ruleType==1" label="主叫">
            <span>为同一渠道下的所有主叫</span>
          </a-form-item>

          <a-form-item has-feedback label="状态" name="status">
            <a-radio-group v-model:value="formData.status">
              <a-radio :value="status.value" v-for="status in statuses" :key="status.label">{{ status.label }}</a-radio>
            </a-radio-group>
          </a-form-item>

          <a-form-item has-feedback label="处罚方式" name="punish">
            <a-select
                v-model:value="formData.punish"
                style="width: 100%"
                placeholder="请选择处罚方式">
              <a-select-option
                  :value="item.value"
                  :label="item.label"
                  v-for="item in punishes"
                  :key="item.value">{{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </div>
      <template #footer>
        <a-button key="back" @click="modalCanceled">取消</a-button>
        <a-button key="submit" type="primary" :loading="modalConfirmLoading" @click="modalConfirm">保存</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import {computed, onMounted, reactive, ref, toRaw, toRefs, watch} from "vue";
import {PageModule} from "@/views/open/common/pagination";
import _lineService from "@/api/open/LineQualityInspections";

export default {
  name: "Rule",
  props: {
    //关键词组列表
    voiceOption: {
      type: Array,
      default: () => []
    },
    //是否为管理员
    isAdminUser: {
      type: String,
      default: () => ''
    },
    //渠道名列表
    userOptions: {
      type: Array,
      default: () => []
    }
  },
  setup(props, context) {
    let formRef = ref();
    let pageModule = reactive(new PageModule());
    let pageSizeChange = (val) => {
      pageModule.setPageSize(val);
      getRuleList();
    };
    let pageChange = (val) => {
      pageModule.setCurrentPage(val);
      getRuleList();
    };

    //查询参数
    let formSearch = reactive({
      // 违规等级，1-特大违规 2-重大违规 3-一般违规 4-轻微违规
      level: null,
      //关键词组id
      keywordTypeId: null,
      // 关键词
      keyword: null,
    });
    let formData = reactive({
      // 违规等级，1-特大违规 2-重大违规 3-一般违规 4-轻微违规
      level: null,
      // 规则类型，0-关键词触发 1-违规升级触发
      ruleType: 0,

      // 关键词组ID，rule_type为0时生效
      keywordTypeId: null,
      // 规则的命中方式，0-未命中触发 1-命中触发 rule_type为0时生效
      keywordHitType: 1,
      // 命中/未命中关键词个数，rule_type为0时生效
      keywordHitNum: 0,

      // 多少天内，rule_type为1时生效
      upgradeDay: null,
      // 触发多少次，rule_type为1时生效
      upgradeNum: null,
      // 违规类型，rule_type为1时生效
      upgradeLevel: null,

      // 状态，0-停用 1-启用
      status: 1,
      // 惩罚，0-无惩罚 1-禁主叫 2-禁终端 3-禁代理
      punish: 0,
    });

    const formRules = {
      level: [
        {required: true, type: 'number', message: '请选择风险等级', trigger: 'blur'}
      ],
      ruleType: [
        {required: true, type: 'number', message: '请选择触发方式', trigger: 'blur'}
      ],
      status: [
        {required: true, type: 'number', message: '请选择状态', trigger: 'blur'}
      ],
      punish: [
        {required: true, type: 'number', message: '请选择处罚方式', trigger: 'blur'}
      ],
    }

    let levels = [
      {label: '特大违规', value: 0},
      {label: '重大违规', value: 1},
      {label: '一般违规', value: 2},
    ]
    let ruleTypes = [
      {label: '关键词触发', value: 0},
      {label: '违规升级触发', value: 1},
    ]
    let keywordHitTypes = [
      {label: '未命中', value: 0},
      {label: '命中', value: 1},
    ]
    let statuses = [
      {label: '正常', value: 1},
      {label: '禁用', value: 0},
    ]
    let punishes = [
      {label: '暂不惩罚', value: 0},
      {label: '禁用当前拨打主叫', value: 1},//禁用主叫 禁用当前拨打主叫
      {label: '禁用当前拨打主叫所在企业', value: 2},//禁用终端 禁用当前拨打主叫所在企业
      {label: '禁用当前主叫企业所属渠道', value: 3},//禁用代理 禁用当前主叫企业所属渠道
    ]

    //当前违规名称
    let currentLevelLabel = computed(() => {
      let find = levels.find(level => level.value == formData.level);
      return find?.label || '';
    })
    //更低一级违规名称
    let lowerLevelLabel = computed(() => {
      let find = levels.find(level => level.value == (formData.level + 1));
      return find?.label || '轻微违规';
    })
    let tableData = ref([]);
    let loading = ref(false);
    let modalConfirmLoading = ref(false);
    let modalVisible = ref(false);

    // let levelDesc = computed(item => levels.find(listItem => listItem.value == item.level)?.label);
    // let ruleTypeDesc = computed(item => ruleTypes.find(listItem => listItem.value == item.ruleType)?.label);
    // let keywordHitTypeDesc = computed(item => keywordHitTypes.find(listItem => listItem.value == item.keywordHitType)?.label);
    // let statusDesc = computed(item => statuses.find(listItem => listItem.value == item.status)?.label);
    // let punishDesc = computed(item => punishes.find(listItem => listItem.value == item.punish)?.label);

    let editData = (item) => {

      formData.id = item?.id;
      // 违规等级，1-特大违规 2-重大违规 3-一般违规 4-轻微违规
      formData.level = item?.level || 1;
      // 规则类型，0-关键词触发 1-违规升级触发
      formData.ruleType = item?.ruleType || 0;

      // 关键词组ID，rule_type为0时生效
      formData.keywordTypeId = item?.keywordTypeId;
      // 规则的命中方式，0-未命中触发 1-命中触发 rule_type为0时生效
      formData.keywordHitType = item?.keywordHitType;
      // 命中/未命中关键词个数，rule_type为0时生效
      formData.keywordHitNum = item?.keywordHitNum;

      // 多少天内，rule_type为1时生效
      formData.upgradeDay = item?.upgradeDay;
      // 触发多少次，rule_type为1时生效
      formData.upgradeNum = item?.upgradeNum;
      // 违规类型，rule_type为1时生效
      formData.upgradeLevel = item?.upgradeLevel;

      // 状态，0-停用 1-启用
      formData.status = item?.status;
      // 惩罚，0-无惩罚 1-禁主叫 2-禁终端 3-禁代理
      formData.punish = item?.punish;

      modalVisible.value = true;
    }
    let deleteData = (item) => {
      console.log('')
    }

    let modalCanceled = () => {
      modalVisible.value = false;
    }
    let modalConfirm = async () => {
      modalVisible.value = false;

      const obj = toRaw(formData);
      // console.log('modalConfirm', obj);
      await formRef.value.validate()
          .then((e) => {
            console.log('modalConfirm values', e, formData, obj);
          })
          .catch((error) => {
            console.log('modalConfirm error', error);
          });

    }

    let resetSearch = async () => {
      formSearch.level = null;
      formSearch.keywordTypeId = null;
      formSearch.keyword = null;
      await getRuleList();
    }
    let getRuleList = async () => {
      loading.value = true;
      let formSearchObj = toRaw(formSearch);
      let pageModuleObj = pageModule.getParamsJson();
      const res = await _lineService._ruleService.getRuleList({...formSearchObj, ...pageModuleObj});
      //keywords: null
      //level: 1
      //name: null
      //punish: 0
      //ruleType: 0
      //status: 1
      //updateTime: null
      //upgradeDay: null
      //upgradeLevel: null
      //upgradeNum: null

      console.log('getRuleList', res);
      tableData.value = res?.data?.records || [];
      pageModule.setTotal(res?.data?.total || 0);
      loading.value = false;
    }
    onMounted(() => {
      getRuleList();
    })
    return {
      pageModule, pageSizeChange, pageChange,
      formSearch, formData, formRules,
      tableData, loading,
      levels, ruleTypes, keywordHitTypes, statuses, punishes,
      currentLevelLabel, lowerLevelLabel,
      // levelDesc, ruleTypeDesc, keywordHitTypeDesc,
      // statusDesc, punishDesc,
      editData, deleteData,
      formRef, modalVisible, modalConfirmLoading,
      modalCanceled, modalConfirm,
      getRuleList, resetSearch,
    };
  },
  computed: {
    // let levelDesc = computed(item => levels.find(listItem => listItem.value == item.level)?.label);
    // let ruleTypeDesc = computed(item => ruleTypes.find(listItem => listItem.value == item.ruleType)?.label);
    // let keywordHitTypeDesc = computed(item => keywordHitTypes.find(listItem => listItem.value == item.keywordHitType)?.label);
    // let statusDesc = computed(item => statuses.find(listItem => listItem.value == item.status)?.label);
    // let punishDesc = computed(item => punishes.find(listItem => listItem.value == item.punish)?.label);
    levelDesc() {
      return item => {
        console.log('levelDesc', this.levels, item)
        return this.levels.find(listItem => listItem.value == item.level)?.label;
      }
    },
    ruleTypeDesc() {
      return item => {
        return this.ruleTypes.find(listItem => listItem.value == item.ruleType)?.label;
      }
    },
    keywordHitTypeDesc() {
      return item => {
        return this.keywordHitTypes.find(listItem => listItem.value == item.keywordHitType)?.label;
      }
    },
    statusDesc() {
      return item => {
        return this.statuses.find(listItem => listItem.value == item.status)?.label;
      }
    },
    punishDesc() {
      return item => {
        return this.punishes.find(listItem => listItem.value == item.punish)?.label;
      }
    },

  },
  methods: {
    async testConfirm() {
      await this.$refs.formRef.validate();
    }
  }
}
</script>

<style lang="less" scoped>

.add-from-s /deep/ .ant-form-item-label > label {
  width: 120px;
}
</style>